import React from "react";

function Footer() {
  return (
    <footer
      className="footer-bg footer-p"
      style={{
        background: "#212121",
      }}
    >
      <div className="footer-top pt-120 pb-90">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/e_colorize,co_white/v1731605146/PrintLogs/Logos/dhy-logsboot_-_7D82_02_snkkeb.png"
                      alt="img"
                      style={{
                        width: "80%",
                      }}
                    />
                  </h2>
                </div>
                <div className="f-contact">
                  <ul>
                    <li>
                      <span>Toronto, Canada</span>
                    </li>
                    <li>
                      <span>printlogs.designitwearit@gmail.com</span>
                    </li>
                    <li>
                      <div className="footer-social">
                        <a href="/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="/">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>Essential</h2>
                </div>
                <div className="footer-link">
                  <ul>
                    <li>
                      <a href="/catalog">Catalog</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                    <li>
                      <a href="/contact">Get In Touch</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>Products</h2>
                </div>
                <div className="footer-link">
                  <ul>
                    <li>
                      <a href="/products?style=Tops">T-shirt & Tops</a>
                    </li>
                    <li>
                      <a href="/products?style=Sweatshirts">
                        {" "}
                        Sweatshirt & Fleece
                      </a>
                    </li>
                    <li>
                      <a href="/products?style=Headwear">Headwear & Caps</a>
                    </li>
                    <li>
                      <a href="/products?style=Accessories">
                        Bags & Accessories
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <div className="f-widget-title">
                  <h2>PrintLogs best</h2>
                </div>
                <div className="recent-blog-footer">
                  <ul>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <div className="thum">
                        {" "}
                        <img
                          src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731259773/PrintLogs/Images/colorful-t-shirts-arranged-in-a-row-on-cloth-rack-2023-11-27-05-26-43-utc-min_warhnr.jpg"
                          alt="img"
                        />
                      </div>
                      <div className="text">
                        {" "}
                        <a href="blog-details.html">Unisex Softstyle T-Shirt</a>
                        <span>By Gildan</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              &copy; 2024 by PrintLogs. All rights reserved. Powered by{" "}
              <a
                href="www.logsboot.com"
                target="_blank"
                style={{
                  color: "#fff",
                  textDecoration: "underline",
                }}
              >
                LogsBoot Technology Leader
              </a>
            </div>
            <div className="col-lg-6 text-right text-xl-right">
              <ul>
                <li>
                  <img src="img/logo/f-client-logo.png" alt="img" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
