import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function Services() {
  return (
    <>
      <Header />
      <main>
        <section
          className="breadcrumb-area d-flex align-items-center"
          style={{
            backgroundImage: "url(img/bg/bdrc-bg.jpg)",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-left">
                  <div className="breadcrumb-title">
                    <h2
                      style={{
                        color: "#fff",
                      }}
                    >
                      Services
                    </h2>
                    <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="/">Home</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Services
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="services-area pt-60 pb-60 p-relative fix"
          style={{
            background: "rgba(243, 252, 235, 0.5) 100%",
          }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="section-title mb-80 text-center">
                  <h5>work process</h5>
                  <h2>
                    How our best printing press <span>working?</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-6">
                <div className="services-item mb-30">
                  <div className="services-thumb">
                    <div className="icon">
                      <img src="img/icon/hw-icon-01.png" alt="img" />
                    </div>
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731258529/PrintLogs/Images/oh-what-fun-it-is-to-ride-motorcycle-themed-chris-2023-11-27-05-20-19-utc-min_sgubju.jpg"
                      alt="img"
                      style={{
                        height: "30vh",
                      }}
                    />
                  </div>
                  <div className="services-content">
                    <h3>
                      <a href="contact">Add your t-shirt design</a>
                    </h3>
                    <p>
                      High-quality product images are essential for showcasing
                      the product.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-item mb-30">
                  <div className="services-thumb">
                    <div className="icon">
                      <img src="img/icon/hw-icon-02.png" alt="img" />
                    </div>
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731256022/PrintLogs/Images/shop-shopping-retail-2024-09-18-01-52-23-utc-min_nwiuom.jpg"
                      alt="img"
                      style={{
                        height: "30vh",
                      }}
                    />
                  </div>
                  <div className="services-content">
                    <h3>
                      <a href="contact">Select cloth quality</a>
                    </h3>
                    <p>
                      High-quality product images are essential for showcasing
                      the product.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-item mb-30">
                  <div className="services-thumb">
                    <div className="icon">
                      <img src="img/icon/hw-icon-03.png" alt="img" />
                    </div>
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731258980/PrintLogs/Images/woman-paying-for-cocktail-with-credit-card-at-bar-2023-11-27-05-21-38-utc-min_xotuj0.jpg"
                      alt="img"
                      style={{
                        height: "30vh",
                      }}
                    />
                  </div>
                  <div className="services-content">
                    <h3>
                      <a href="contact">Make your payment</a>
                    </h3>
                    <p>
                      High-quality product images are essential for showcasing
                      the product.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="offer-box-area p-relative mt-80">
          <div className="animations-01">
            <img src="img/bg/ani-img-02.png" alt="an-img-01" />
          </div>
          <div className="animations-02">
            <img src="img/bg/ani-img-02.png" alt="an-img-01" />
          </div>
          <div
            className="container pt-120 pb-120"
            style={{
              backgroundImage: "url(img/bg/offer-bg.png)",
            }}
          >
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8 col-md-12 col-sm-12 text-center">
                <div className="offer-text">
                  <div className="section-title mb-30 ">
                    <h5>Printed and shipped on Demand!</h5>
                    <h2>Get bulk discount on bulk orders.</h2>
                  </div>
                  <div className="slider-btn mt-20">
                    <a href="product-details.html" className="link-btn active">
                      Shop Now{" "}
                      <i className="fa-regular fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="event event03 pb-80 pt-80 p-relative fix"
          style={{
            backgroundImage: "url('./img/bg/faq-bg.png')",
            backgroundPosition: "center right",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 col-md-12 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="faq-img">
                  <img src="img/bg/faq-img.png" alt="img" />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div
                  className="faq-wrap pl-100 wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                >
                  <div className="section-title mb-80 text-left">
                    <h2>
                      Begin with initial investment.
                      <br />
                      of <span>$0.00</span>
                    </h2>
                  </div>
                  <div className="accordion" id="accordionExample">
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                          <button
                            className="faq-btn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                          >
                            Step 1. Select Product
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body">
                          Select from various high-quality products, featuring
                          trusted brands.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                          <button
                            className="faq-btn collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                          >
                            Step 2. Upload Design
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body">Design your product.</div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                          <button
                            className="faq-btn collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                          >
                            Step 3. Place Order
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body">
                          Process the payment and we will take care of
                          everything from production to shipping.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Services;
