import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  fetchProductData,
  fetchProductSpecsData,
} from "../Services/apiService";

function Product() {
  const navigate = useNavigate();
  const location = useLocation();
  const { styleID, title, brandName, description, baseCategory } =
    location.state || {};
  const [search, setSearch] = useState("");
  const [error, setError] = useState(null);
  const [prodData, setProdData] = useState([]);
  const [bigImage, setBigImage] = useState("");
  const [prodSpecs, setProdSpecs] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchProductSpecsData(styleID);
        setProdSpecs(result);
      } catch (error) {
        setError("Failed to load data");
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async (styleID) => {
      try {
        const result = await fetchProductData(styleID);
        setProdData(result);
        setBigImage(
          result?.uniqueColorItems[0]?.colorOnModelFrontImage
            ? result?.uniqueColorItems[0]?.colorOnModelFrontImage
            : result?.uniqueColorItems[0]?.colorFrontImage ||
                result?.uniqueColorItems[0]?.colorSideImage
        );
      } catch (error) {
        setError("Failed to load data");
      }
    };
    if (styleID === undefined) {
      navigate("/products");
    } else {
      loadData(styleID);
    }
  }, []);
  return (
    <>
      <Header />
      <main>
        <section
          className="breadcrumb-area d-flex align-items-center"
          style={{
            backgroundImage: "url(img/bg/bdrc-bg.jpg)",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-left">
                  <div className="breadcrumb-title">
                    <h2
                      style={{
                        color: "#fff",
                      }}
                    >
                      Product Details
                    </h2>
                    <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="/">Home</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            <a href={`/products?style=${baseCategory}`}>
                              Catalog
                            </a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Product Details
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="shop-banner-area pt-60 pb-60 "
          data-animation="fadeInUp animated"
          data-delay=".2s"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-7">
                <div className="shop-thumb-tab mb-30">
                  <ul className="nav" id="myTab2" role="tablist">
                    {prodData?.uniqueColorItems?.map((item, i) => {
                      return (
                        <li
                          className="nav-item hover-zoom-container"
                          key={i}
                          onClick={() => {
                            setBigImage(
                              item?.colorOnModelFrontImage ||
                                item?.colorFrontImage
                            );
                          }}
                        >
                          <img
                            src={`https://cdn.ssactivewear.com/${
                              item?.colorOnModelFrontImage
                                ? item?.colorOnModelFrontImage
                                : item?.colorFrontImage || item?.colorSideImage
                            }`}
                            alt=""
                            style={{
                              width: "10vh",
                              cursor: "pointer",
                            }}
                            className="hover-zoom-image"
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="product-details-img mb-30">
                  <div className="tab-content" id="myTabContent2">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                    >
                      <div className="product-large-img">
                        <img
                          src={`https://cdn.ssactivewear.com/${bigImage}`}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5">
                <div className="product-details mb-30">
                  <div className="product-details-title">
                    <p>{brandName}</p>
                    <h1>{title}</h1>
                    {prodData?.uniqueColorItems?.length ? (
                      <div className="price details-price pb-30 mb-20">
                        <span>
                          CAD $
                          {Number(
                            prodData?.uniqueColorItems[0]?.piecePrice
                          ).toFixed(2) || 0.0}
                        </span>
                        <span className="old-price">
                          CAD $
                          {Number(
                            prodData?.uniqueColorItems[0]?.piecePrice + 10
                          ).toFixed(2) || 0.0}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  <div className="product-cat mt-30 mb-30">
                    <span>Color(s): </span>
                    <br />
                    {prodData?.uniqueColorItems?.map((item, i) => {
                      return (
                        <span
                          key={i}
                          style={{
                            border: `2px solid ${item?.color1}`,
                            padding: 5,
                            marginRight: 5,
                            fontWeight: "bold",
                            cursor: "pointer",
                            marginBottom: 5,
                            display: "inline-block",
                          }}
                          onClick={() => {
                            setBigImage(
                              item?.colorOnModelFrontImage ||
                                item?.colorFrontImage ||
                                item?.colorSideImage
                            );
                          }}
                        >
                          <img
                            src={`https://cdn.ssactivewear.com/${
                              item?.colorOnModelFrontImage
                                ? item?.colorOnModelFrontImage
                                : item?.colorFrontImage || item?.colorSideImage
                            }`}
                            alt=""
                            style={{
                              width: "35px",
                              cursor: "pointer",
                            }}
                          />
                          {item?.colorGroupName}
                        </span>
                      );
                    })}
                  </div>
                  <div className="product-cat mt-30 mb-30">
                    <span>Size(s): </span>
                    <br />
                    {prodData?.uniqueSizeItems?.map((item, i) => {
                      return (
                        <span
                          key={i}
                          style={{
                            border: `2px solid #ccc`,
                            padding: 5,
                            marginRight: 5,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {item?.sizeName}
                        </span>
                      );
                    })}
                  </div>
                  <div className="product-details-action">
                    <form>
                      <a className="btn btn-black" href="contact">
                        <i className="fa-regular fa-cart-shopping"></i> Contact
                        Us to Order
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="product-desc-area pb-55">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="bakix-details-tab">
                  <ul
                    className="nav text-center justify-content-center pb-30 mb-50"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="desc-tab"
                        data-bs-toggle="tab"
                        href="#id-desc"
                        aria-bs-controls="home"
                        aria-selected="true"
                      >
                        Description
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="id-add-in"
                        data-bs-toggle="tab"
                        href="#id-add"
                        aria-bs-controls="profile"
                        aria-selected="false"
                      >
                        Specifications
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="id-desc"
                    role="tabpanel"
                    aria-labelledby="desc-tab"
                  >
                    <div className="event-text mb-40">
                      <p dangerouslySetInnerHTML={{ __html: description }}></p>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="id-add"
                    role="tabpanel"
                    aria-labelledby="id-add-in"
                  >
                    <div className="additional-info">
                      <div className="table-responsive">
                        <h4>Additional information</h4>
                        <table className="table">
                          <tbody>
                            {prodSpecs?.length > 0 ? (
                              prodSpecs?.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <th>{item?.specName}</th>
                                    <td className="product_weight">
                                      {item?.value}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <th>No Data Available</th>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="scrollbox4 pt-120 pb-60"
          style={{
            background: "#212121",
          }}
        >
          <div className="scrollbox scrollbox--secondary scrollbox--reverse">
            <div className="scrollbox__item">
              {" "}
              <div className="section-t">
                {" "}
                <img src="img/bg/scrollbox-text.png" alt="img" />
              </div>
            </div>
            <div className="scrollbox__item">
              {" "}
              <div className="section-t">
                {" "}
                <img src="img/bg/scrollbox-text.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Product;
