import axios from "axios";

//var API_BASE_URL = "https://638e-65-93-60-21.ngrok-free.app/v1";
var API_BASE_URL = "https://printlogs-17738dc65798.herokuapp.com/v1";

// Get data from the server
export const fetchStylesData = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/styles`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchProductData = async (styleId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/product`, {
      styleId,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchProductSpecsData = async (styleId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/productSpec`, {
      styleId,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
