import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function Catalog() {
  return (
    <>
      <Header />
      <main>
        <section
          className="breadcrumb-area d-flex align-items-center"
          style={{
            backgroundImage: "url(img/bg/bdrc-bg.jpg)",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-left">
                  <div className="breadcrumb-title">
                    <h2
                      style={{
                        color: "#fff",
                      }}
                    >
                      Catalog
                    </h2>
                    <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="/">Home</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Catalog{" "}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="gallery-area fix pt-60 pb-60">
          <div className="container">
            <h2 className="text-center">Find our best</h2>
            <h5 className="text-center">
              Here are some of the most popular product categories in our
              catalog.
            </h5>

            <div className="row mt-5">
              <div className="col-lg-3 col-md-12 mb-30">
                <a href="/products?style=T-Shirts - Premium">
                  <figure className="gallery-image">
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731259773/PrintLogs/Images/colorful-t-shirts-arranged-in-a-row-on-cloth-rack-2023-11-27-05-26-43-utc-min_warhnr.jpg"
                      alt="img"
                      className="img"
                    />
                    <figcaption>
                      <div className="icon">
                        <img
                          src="img/gallery/g-btn.png"
                          alt="img"
                          className="img"
                        />{" "}
                      </div>
                      <div className="text">
                        <span>T-shirt</span>
                        <h4>& Tops</h4>
                      </div>
                    </figcaption>
                  </figure>
                </a>
              </div>
              <div className="col-lg-3 col-md-12 mb-30">
                <a href="/products?style=Fleece - Premium - Hood">
                  <figure className="gallery-image">
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731260125/PrintLogs/Images/stack-of-autumn-basic-turtlenecks-in-trendy-colors-2023-11-27-05-16-22-utc-min_jts9sp.jpg"
                      alt="img"
                      className="img"
                    />
                    <figcaption>
                      <div className="icon">
                        <img
                          src="img/gallery/g-btn.png"
                          alt="img"
                          className="img"
                        />{" "}
                      </div>
                      <div className="text">
                        <span>Sweatshirts</span>
                        <h4>& Fleece</h4>
                      </div>
                    </figcaption>
                  </figure>
                </a>
              </div>

              <div className="col-lg-3 col-md-12 mb-30">
                <a href="/products?style=Headwear">
                  <figure className="gallery-image">
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731368336/PrintLogs/Images/box-with-beanies-and-cotton-on-white-background-2023-11-27-05-25-47-utc-min_ay6z0h.jpg"
                      alt="img"
                      className="img"
                    />
                    <figcaption>
                      <div className="icon">
                        <img
                          src="img/gallery/g-btn.png"
                          alt="img"
                          className="img"
                        />{" "}
                      </div>
                      <div className="text">
                        <span>Headwear</span>
                        <h4>& Caps</h4>
                      </div>
                    </figcaption>
                  </figure>
                </a>
              </div>
              <div className="col-lg-3 col-md-12 mb-30">
                <a href="/products?style=Bags">
                  <figure className="gallery-image">
                    <img
                      src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731260473/PrintLogs/Images/stylish-eco-bags-and-twigs-on-white-background-2023-11-27-05-10-58-utc-min_rjy5kt.jpg"
                      alt="img"
                      className="img"
                    />
                    <figcaption>
                      <div className="icon">
                        <img
                          src="img/gallery/g-btn.png"
                          alt="img"
                          className="img"
                        />{" "}
                      </div>
                      <div className="text">
                        <span>Bags</span>
                        <h4>& Accessories</h4>
                      </div>
                    </figcaption>
                  </figure>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div
          className="scrollbox4 pt-120 pb-60"
          style={{
            backgroundColor: "#212121",
          }}
        >
          <div className="scrollbox scrollbox--secondary scrollbox--reverse">
            <div className="scrollbox__item">
              {" "}
              <div className="section-t">
                {" "}
                <img src="img/bg/scrollbox-text.png" alt="img" />
              </div>
            </div>
            <div className="scrollbox__item">
              {" "}
              <div className="section-t">
                {" "}
                <img src="img/bg/scrollbox-text.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Catalog;
