import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function Contact() {
  return (
    <>
      <Header />
      <main>
        <section
          className="breadcrumb-area d-flex align-items-center"
          style={{
            backgroundImage: "url(img/bg/bdrc-bg.jpg)",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-left">
                  <div className="breadcrumb-title">
                    <h2
                      style={{
                        color: "#fff",
                      }}
                    >
                      Contact Us
                    </h2>
                    <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="/">Home</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            About
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-area pt-60 pb-60 p-relative fix">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-xl-6">
                <div
                  className="section-title text-center mb-80 center-align  wow fadeInDown animated"
                  data-animation="fadeInDown"
                  data-delay=".4s"
                >
                  <h5>let’s talk</h5>
                  <h2>
                    Need Help? <span>Team is here</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-9 col-xl-9">
                <div className="contact-bg02 text-center">
                  <form
                    action="mail.php"
                    method="post"
                    className="contact-form wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="contact-field p-relative c-name mb-20">
                          <i className="fa-light fa-user"></i>
                          <input
                            type="text"
                            id="firstn"
                            name="firstn"
                            placeholder="First Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <i className="fa-light fa-envelope"></i>
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <i className="fa-light fa-phone"></i>
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Phone No."
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contact-field p-relative c-name mb-20">
                          <i className="fa-sharp fa-light fa-money-check-pen"></i>
                          <input
                            type="text"
                            id="subject"
                            name="subject"
                            placeholder="Subject"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-message mb-30">
                          <i className="fa-light fa-pencil"></i>
                          <textarea
                            name="message"
                            id="message"
                            cols="30"
                            rows="10"
                            placeholder="Write comments"
                          ></textarea>
                        </div>
                        <div className="slider-btn">
                          <button
                            href="contact.html"
                            className="btn mt-25"
                            data-animation="fadeInLeft"
                            data-delay=".4s"
                          >
                            Submit Now{" "}
                            <i className="fal fa-long-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature-area p-relative">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                <div className="feature-box">
                  <div className="icon">
                    <img src="img/icon/f-global-network.png" alt="img" />
                  </div>
                  <div className="text text-left">
                    <h5>
                      Worldwide <strong>shipping</strong>
                    </h5>
                    <p>Shipping all over the world.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                <div className="feature-box">
                  <div className="icon">
                    <img src="img/icon/f-flower.png" alt="img" />
                  </div>
                  <div className="text text-left">
                    <h5>
                      On demand <strong>service</strong>
                    </h5>
                    <p>No minimums, on demand service.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                <div className="feature-box">
                  <div className="icon">
                    <img src="img/icon/f-tshirt.png" alt="img" />
                  </div>
                  <div className="text text-left">
                    <h5>
                      Custom t-shirt <strong>design</strong>
                    </h5>
                    <p>Designs and customize 400+ products.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 text-center">
                <div className="feature-box">
                  <div className="icon">
                    <img src="img/icon/f-support.png" alt="img" />
                  </div>
                  <div className="text text-left">
                    <h5>
                      Publish &&nbsp;<strong>Earn</strong>
                    </h5>
                    <p>Connect your products to your online store.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Contact;
