import React, { useState } from "react";
import { Modal } from "react-bootstrap";

function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <>
      <Modal
        show={showMobileMenu}
        onHide={() => {
          setShowMobileMenu(false);
        }}
        keyboard={false} // Prevents closing on "Escape" key
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731605146/PrintLogs/Logos/dhy-logsboot_-_7D82_02_snkkeb.png"
              alt="logo"
              style={{
                width: "20vh",
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ul
              style={{
                listStyle: "none",
                margin: 0,
                padding: 0,
              }}
            >
              <li
                style={{
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                <a
                  className="menu-item"
                  href="/catalog"
                  style={{
                    width: "100%",
                  }}
                >
                  Catalog
                </a>
              </li>

              <li
                style={{
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                <a
                  className="menu-item"
                  href="/services"
                  style={{
                    width: "100%",
                  }}
                >
                  Services
                </a>
              </li>
              <li
                style={{
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                <a
                  className="menu-item"
                  href="/contact"
                  style={{
                    width: "100%",
                  }}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <header className="header-area header-two">
        <div id="header-sticky" className="menu-area">
          <div className="container-fluid pl-80 pr-80">
            <div className="second-menu">
              <div
                className="row align-items-center"
                style={{
                  position: "relative",
                }}
              >
                <div className="col-xl-2 col-lg-2 col-sm-6 col-xs-6">
                  <div className="logo">
                    <a href="/">
                      <img
                        src="https://res.cloudinary.com/dcfotquxt/image/upload/v1731605146/PrintLogs/Logos/dhy-logsboot_-_7D82_02_snkkeb.png"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-9 text-right">
                  <div className="main-menu">
                    <nav id="mobile-menu">
                      <ul>
                        <li>
                          <a href="/catalog">Catalog</a>
                        </li>
                        <li>
                          <a href="/services">Services</a>
                        </li>

                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 text-right d-none d-xl-block mt-20 mb-20">
                  <div className="login">
                    <ul>
                      <li>
                        <a href="contact" className="btn active">
                          Contact{" "}
                          <i className="fa-regular fa-long-arrow-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-5 mobileShow">
                  <i
                    className="fa fa-bars fa-lg"
                    onClick={() => {
                      setShowMobileMenu(true);
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
