import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { fetchStylesData } from "../Services/apiService";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Products() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const style = params.get("style") || "Headwear";
  const [data, setData] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedCatData, setSelectedCatData] = useState([]);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchStylesData();
        setData(result);
        setSelectedCat(style);
        setSelectedCatData(
          result.filter((item) => item?.baseCategory === style)
        );
      } catch (error) {
        setError("Failed to load data");
      }
    };

    loadData();
  }, []);
  return (
    <>
      <Header />
      <main>
        <section
          className="breadcrumb-area d-flex align-items-center"
          style={{
            backgroundImage: "url(img/bg/bdrc-bg.jpg)",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-left">
                  <div className="breadcrumb-title">
                    <h2
                      style={{
                        color: "#fff",
                      }}
                    >
                      Products
                    </h2>
                    <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="/">Home</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Products{" "}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="shop-area pt-60 pb-60 p-relative "
          data-animation="fadeInUp animated"
          data-delay=".2s"
        >
          <div className="container">
            <div
              className="row"
              style={{
                marginBottom: 20,
              }}
            >
              <div className="col-lg-4 col-sm-12">
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    var currData = data.filter(
                      (item) => item?.baseCategory === selectedCat
                    );
                    var searchedData = [
                      {
                        baseCategory: selectedCat,
                        subCats: currData[0]?.subCats?.filter(
                          (item) =>
                            item?.title
                              ?.toLowerCase()
                              .indexOf(e?.target?.value?.toLowerCase()) >= 0
                        ),
                      },
                    ];
                    setSelectedCatData(searchedData);
                  }}
                  placeholder="Search Product..."
                  style={{
                    height: 43,
                    margin: "8px 0px",
                    paddingLeft: 10,
                  }}
                />
              </div>
              <div className="col-lg-4 col-sm-12">
                <h3
                  style={{
                    margin: "12px 0px",
                    textAlign: "center",
                  }}
                >
                  All Products
                </h3>
              </div>
              <div
                className="col-lg-4 col-sm-12 text-right"
                style={{
                  margin: "8px 0",
                }}
              >
                <select
                  name="orderby"
                  className="orderby"
                  value={selectedCat}
                  aria-label="Shop order"
                  onChange={(e) => {
                    setSelectedCat(e?.target?.value);
                    setSelectedCatData(
                      data.filter(
                        (item) => item?.baseCategory === e?.target?.value
                      )
                    );
                  }}
                >
                  <option value="">Select Styles</option>
                  {data?.map((cat, i) => {
                    return (
                      <option value={cat?.baseCategory} key={i}>
                        {cat?.baseCategory}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row align-items-center">
              {selectedCat === "" ? (
                <h3
                  style={{
                    textAlign: "center",
                  }}
                >
                  Please select category....
                </h3>
              ) : (
                selectedCatData[0]?.subCats?.map((item, i) => {
                  return (
                    <div
                      className="col-lg-2 col-md-2 col-sm-6 col-xs-6"
                      key={i}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className="product mb-40">
                        <div className="product__img hover-zoom-container">
                          <a
                            onClick={() => {
                              navigate("/product", {
                                state: item,
                              });
                            }}
                          >
                            <img
                              src={`https://cdn.ssactivewear.com/${item?.styleImage}`}
                              alt=""
                              className="hover-zoom-image"
                            />
                          </a>
                        </div>
                        <div className="product__content text-center pt-30">
                          <h4
                            className="pro-title"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "13vw",
                              marginBottom: 0,
                            }}
                          >
                            <a
                              href="/"
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {item?.title}
                            </a>
                          </h4>
                          <div className="price">
                            <span
                              style={{
                                fontSize: 14,
                              }}
                            >
                              {item?.baseCategory}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Products;
